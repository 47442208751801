/* global googletag */

Apt.fn.make('ads', {
	/**
	 * Initialize module
	 *
	 * @param {Object} options
	 * @param {Function} [options.target]
	 * @param {Function} options.units
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = scope.$private;

		priv.options = options;
		priv.uid = LS.util.uid();
		priv.ads = {};

		$._win.googletag = $._win.googletag || {};
		$._win.googletag.cmd = $._win.googletag.cmd || [];

		$._win.pbjs = $._win.pbjs || {
			que: []
		};

		priv.setup();

		return scope;
	},

	/**
	 * Load ads
	 */
	load: function() {
		this.$private.setup(true);
	},

	/**
	 * Unload module
	 */
	unload: function() {
		var priv = this.$private,
			uid = priv.uid,
			ad;

		clearTimeout(priv.delay);

		$.events.reset(uid);
		$.scroll.reset(uid);

		Object.keys(priv.ads).forEach(function(id) {
			ad = priv.ads[id];

			googletag.cmd.push(function() {
				googletag.destroySlots([
					ad.slot
				]);
			});

			if (pbjs.removeAdUnit) {
				pbjs.removeAdUnit(ad.unit);
			}
		});

		priv.ads = {};
		priv.units = null;
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		var scope = this,
			priv = scope.$private;

		if (priv.units) {
			scope.unload();
		}

		priv.uid = null;
	}
}, {
	/**
	 * Map format sizes
	 */
	formats: {
		card: [
			'fluid',
			[336, 280],
			[300, 250],
			[250, 250],
			[200, 200],
			[320, 100]
		]
	},

	/**
	 * Map ad placements
	 */
	placements: {
		property: 'property-card',
		properties: 'properties-card'
	},

	/**
	 * Load ads
	 *
	 * @param {Boolean} [display=false]
	 */
	setup: function(display) {
		var scope = this,
			fn = function() {
				if (called) {
					return;
				}

				called = true;

				scope.fetch();
				scope.display();
			},
			called;

		scope.units = scope.options.units();

		clearTimeout(scope.delay);

		if (! scope.units.length) {
			return;
		}

		scope.context = Apt.panel.opened() ?
			'$panelInner' : null;

		if ($._win.crawler) {
			scope.units.closest('.unit')
				.hide();

			return;
		}

		if (display || $._html.scrollTop || scope.units.visible()) {
			fn();

			return;
		}

		$(Apt.panel.opened() ? '$panelInner' : $._win).on('scroll', fn, {
			once: true,
			namespace: scope.uid
		});

		scope.delay = setTimeout(fn, 3000);
	},

	/**
	 * Fetch ad assets
	 */
	fetch: function() {
		var scope = this;

		if (Apt.ads.loaded) {
			return;
		}

		Apt.ads.loaded = true;

		$.assets.load({
			js: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
			error: function() {
				scope.recover();
			}
		});
	},

	/**
	 * Display ads
	 */
	display: function() {
		var scope = this,
			url = 'https://www.landsearch.com' + $._loc.pathname,
			target = $.extend(
				$.context('targeting', {}),
				scope.options.target ?
					scope.options.target() : {}
			),
			keywords = [
				'land',
				'real estate'
			],
			ad;

		scope.units.each(function(el, index) {
			var width = $(el).width(),
				sizes = scope.formats[el.dataset.format]
					.filter(function(size) {
						return ! Array.isArray(size) || width >= size[0];
					});

			if (sizes.length) {
				scope.ads[el.id] = {
					unit: '/22218592200/' + scope.placements[el.dataset.unit],
					sizes: sizes,
					position: el.dataset.position || (index + 1)
				};
			}
		});

		if (! Object.keys(scope.ads).length) {
			return;
		}

		Object.keys(target).forEach(function(key) {
			$.toArray(target[key]).forEach(function(value) {
				if (typeof value === 'string') {
					keywords.push(value.replace(/-/g, ' '));
				}
			});
		});

		target.env = $.env();

		target.theme = LS.common.isDark() ?
			'dark' : 'light';

		pbjs.bidderSettings = {
			standard: {
				storageAllowed: true
			}
		};

		pbjs.que.push(function() {
			pbjs.setConfig({
				pageUrl: url,
				bidderTimeout: 650,
				site: {
					content: {
						language: 'en'
					}
				},
				ortb2: {
					site: {
						keywords: keywords.join(', ')
					}
				},
				priceGranularity: {
					buckets: [
						{
							max: 15,
							increment: 0.01
						},
						{
							max: 30,
							increment: 0.05
						},
						{
							max: 60,
							increment: 0.25
						},
						{
							max: 120,
							increment: 0.5
						}
					]
				},
				userSync: {
					iframeEnabled: true,
					filterSettings: {
						iframe: {
							bidders: '*',
							filter: 'include'
						}
					},
					userIds: [
						{
							name: 'sharedId',
							storage: {
								expires: 365,
								name: '_sharedid',
								type: 'html5'
							}
						}
					]
				}
			});
		});

		googletag.cmd.push(function() {
			if (! scope.uid) {
				return;
			}

			if (! Apt.ads.allowed) {
				var received = function() {
					Apt.ads.allowed = true;

					try {
						scope.units.parent()
							.removeClass('-recover');

						googletag.pubads()
							.removeEventListener('slotResponseReceived', received);
					} catch (e) {
						//
					}
				};

				googletag.pubads()
					.addEventListener('slotResponseReceived', received);
			}

			googletag.pubads()
				.set('page_url', url);

			if (LS.store.get('opt_out')) {
				googletag.pubads()
					.setPrivacySettings({
						restrictDataProcessing: true
					});
			}

			Object.keys(scope.ads).forEach(function(id) {
				ad = scope.ads[id];

				var slot = googletag.defineSlot(ad.unit, ad.sizes, id);

				slot.setTargeting('position', ad.position);

				Object.keys(target).forEach(function(key) {
					slot.setTargeting(key, target[key]);
				});

				slot.addService(
					googletag.pubads()
				);

				ad.slot = slot;
			});

			if (! Apt.ads.enabled) {
				Apt.ads.enabled = true;

				googletag.pubads()
					.disableInitialLoad();

				googletag.enableServices();
			}

			Object.keys(scope.ads).forEach(function(id) {
				googletag.display(id);
			});

			scope.refresh(scope.uid);
		});
	},

	/**
	 * Refresh ad units on scroll
	 *
	 * @param {String} namespace
	 */
	refresh: function(namespace) {
		var scope = this,
			checked;

		scope.units.each(function(el) {
			LS.util.scrolled(el, namespace, function(e) {
				var ad = scope.ads[e.target.id],
					handle = function() {
						scope.handle(ad);
					};

				if (ad) {
					pbjs.que.push(function() {
						scope.add(ad);

						pbjs.requestBids({
							adUnitCodes: [
								ad.unit
							],
							bidsBackHandler: handle
						});
					});

					setTimeout(handle, 1000);

					if (! checked && ! Apt.ads.allowed) {
						checked = true;

						setTimeout(function() {
							scope.recover();
						}, 3500);
					}
				}
			}, {
				context: scope.context,
				margin: scope.options.margin(el)
			});
		});
	},

	/**
	 * Add unit
	 *
	 * @param {Object} ad
	 */
	add: function(ad) {
		if ($.env() === 'local') {
			return;
		}

		var floor = 2,
			property = LS.filters.isProperty(),
			root = property ?
				'properties' : 'directory';

		if (property && LS.filters.isEntry()) {
			root = 'property';
		}

		pbjs.addAdUnits({
			code: ad.unit,
			ortb2Imp: {
				ext: {
					gpid: '/' + root + '/' + ad.position
				}
			},
			mediaTypes: {
				banner: {
					sizes: ad.sizes.slice(1)
				},
				native: {
					sendTargetingKeys: false,
					image: {
						required: true,
						sizes: [1200, 627]
					},
					title: {
						required: true,
						len: 90
					},
					body: {
						len: 160
					}
				}
			},
			bids: [
				{
					bidder: 'appnexus',
					params: {
						placementId: 27532336,
						allowSmallerSizes: true,
						reserve: floor
					}
				},
				{
					bidder: 'ix',
					params: {
						siteId: '900892',
						bidFloor: floor,
						bidFloorCur: 'USD'
					}
				},
				{
					bidder: 'medianet',
					params: {
						cid: '8CUASB4P3',
						crid: '599585305',
						bidfloor: floor
					}
				},
				{
				bidder: 'sharethrough',
					params: {
						pkey: 'kcHFHb66LXvWzdViwGSZnAnN',
						floor: floor
					}
				},
				{
					bidder: 'triplelift',
					params: {
						inventoryCode: 'LandSearch_Properties_Card_Prebid',
						floor: floor
					}
				}
			]
		});
	},

	/**
	 * Handle response
	 *
	 * @param {Object} ad
	 */
	handle: function(ad) {
		if (ad.done) {
			return;
		}

		ad.done = true;

		googletag.cmd.push(function() {
			if (pbjs.setTargetingForGPTAsync) {
				pbjs.setTargetingForGPTAsync();
			}

			googletag.pubads()
				.refresh([
					ad.slot
				]);

			if (pbjs.removeAdUnit) {
				pbjs.removeAdUnit(ad.unit);
			}
		});
	},

	/**
	 * Verify ad support
	 */
	recover: function() {
		var scope = this;

		if (Apt.ads.allowed || ! scope.units) {
			return;
		}

		scope.units.parent()
			.addClass('-recover');

		if (! scope.blocked) {
			scope.blocked = true;

			LS.analytics.event('ad_block');
		}
	}
});