LS.filters = {
	/**
	 * Reset cached data on preload
	 */
	listen: function() {
		var scope = this;

		$($._win).on('preload', function() {
			delete scope.parsed;
		});
	},

	/**
	 * Get filter data
	 *
	 * @param {String} [key]
	 * @returns {*}
	 */
	get: function(key) {
		var data = (this.parsed || this.parse()).query;

		return key ?
			data[key] : $.copy(data);
	},

	/**
	 * Get filtered root
	 *
	 * @returns {*}
	 */
	root: function() {
		return this.parse().root;
	},

	/**
	 * Determine if the current page is a directory route
	 *
	 * @param {String} [segment]
	 * @returns {Boolean}
	 */
	isDirectory: function(segment) {
		return Boolean(LS.directory[
			typeof segment === 'undefined' ?
				$.routes.segments(0) : segment
		]);
	},

	/**
	 * Determine if the current page is a property route
	 *
	 * @param {String} [segment]
	 * @returns {Boolean}
	 */
	isProperty: function(segment) {
		segment = typeof segment === 'undefined' ?
			$.routes.segments(0) : segment;

		return Boolean(
			segment === 'properties' ||
			LS.formats[segment] ||
			LS.types[segment] ||
			LS.tags[segment]
		);
	},

	/**
	 * Determine if the current page is an entry route
	 *
	 * @param {String} [segment]
	 * @returns {Boolean}
	 */
	isEntry: function(segment) {
		segment = typeof segment === 'undefined' ?
			$.routes.segments(2) : segment;

		return /^\d{6,}$/.test(segment);
	},

	/**
	 * Get parsed request data
	 *
	 * @param {Array} [segments]
	 * @param {Boolean} [cached=false]
	 * @returns {Object}
	 */
	parse: function(segments, cached) {
		var scope = this,
			parsed = {
				filters: {},
				structured: {}
			},
			canonical = '',
			query = {},
			root = '',
			i = 0,
			filtered,
			section,
			segment,
			skip;

		if (scope.parsed && ! cached) {
			return scope.parsed;
		}

		segments = segments || $.routes.segments();

		if (scope.isProperty(segments[0])) {
			section = 'property';

			skip = segments[0] === 'properties';

			if (skip) {
				canonical = root = '/properties';
			}
		} else if (segments[1] === 'saves') {
			section = 'property';
		} else if (segments[0] === 'collections') {
			section = 'property';

			parsed.filters = {
				collection: segments[1]
			};
		}

		if (! section && scope.isDirectory(segments[0])) {
			section = 'directory';
		}

		if (section) {
			query.page = 1;
		}

		for (; i < segments.length; i++) {
			segment = segments[i];

			if (skip) {
				root += skip ?
					'' : '/' + segment;
				canonical += skip ?
					'' : '/' + segment;

				skip = false;

				continue;
			}

			if (
				segment === 'account' ||
				segment === 'collections'
			) {
				skip = true;

				continue;
			}

			if (segment === 'search') {
				filtered = skip = true;

				parsed.filters = {
					search: segments[i + 1]
				};

				canonical += '/' + segment + '/' + segments[i + 1];

				continue;
			}

			if (segment === 'filter') {
				filtered = skip = true;

				parsed.filters = scope.extract(segments[i + 1]);

				continue;
			}

			if (/p\d+/.test(segment)) {
				query.page = Number(segment.slice(1));

				if (! filtered && query.page > 1) {
					canonical += '/' + segment;
				}

				continue;
			}

			if (! section) {
				canonical += '/' + segment;

				continue;
			}

			var key = LS.filters.identify(segment, section);

			if (key) {
				root += '/' + segment;
				canonical += '/' + segment;

				parsed.structured[key] = segment;

				continue;
			}

			canonical += '/' + segment;

			query.location = segment;
		}

		parsed.root = root;
		parsed.canonical = $.get('appUrl') + canonical;

		if (section) {
			parsed.section = section;
		}

		parsed.query = $.extend(
			query, parsed.structured, parsed.filters
		);

		if (! cached) {
			scope.parsed = parsed;
		}

		return parsed;
	},

	/**
	 * Identify segment value
	 *
	 * @param {String} segment
	 * @param {String} [section='property']
	 * @returns {string}
	 */
	identify: function(segment, section) {
		if (section === 'directory') {
			if (LS.directory[segment]) {
				return 'type';
			}

			for (var type in LS.directory) {
				if (LS.directory[type].indexOf(segment) > -1) {
					return 'specialty';
				}
			}
		} else {
			if (LS.formats[segment]) {
				return 'format';
			}

			if (LS.types[segment]) {
				return 'type';
			}

			if (LS.tags[segment]) {
				return 'tag';
			}
		}
	},

	/**
	 * Parse filter string into object
	 *
	 * @param {String} [criteria]
	 * @param {Boolean} [filter=true] - remove empty values
	 * @returns {Object}
	 */
	extract: function(criteria, filter) {
		criteria = criteria || '';

		var params = this.unserialize(criteria),
			data = {};

		Object.keys(params).forEach(function(key) {
			var parts = key.split('['),
				value = params[key];

			if (! filter || (filter && value)) {
				if (parts.length === 1) {
					data[key] = value;

					return;
				}

				var sub = parts[1].slice(0, -1);

				key = parts[0];

				value = LS.util.parseNumber(value);

				if (! sub) {
					data[key] = value;
				} else {
					if (! data[key]) {
						data[key] = {};
					}

					data[key][sub] = value;
				}
			}
		});

		if (Array.isArray(data.sort)) {
			data.sort = data.sort.join('+');
		}

		return data;
	},

	/**
	 * Check if extended filters are applied
	 *
	 * @param {Boolean} [model=false]
	 * @returns {Number}
	 */
	count: function(model) {
		var filters = model ?
				$.app.filters.$get() : this.get(),
			count = 0;

		Apt.filters.$private.more.forEach(function(key) {
			if (filters[key]) {
				count++;
			}
		});

		[
			'price',
			'size',
			'space'
		].forEach(function(key) {
			if (filters[key] && (
				filters[key].min ||
				filters[key].max
			)) {
				count++;
			}
		});

		return count;
	},

	/**
	 * Merge filters into app
	 *
	 * @param {Object} data
	 */
	merge: function(data) {
		var scope = this;

		if (! scope.parsed) {
			scope.parse();
		}

		$.extend(scope.parsed.query, data);
	},

	/**
	 * Compile criteria into structured endpoint
	 *
	 * @param {String} [base]
	 * @param {Object} [criteria]
	 * @param {Boolean} [paged=false]
	 * @returns {String}
	 */
	build: function(base, criteria, paged) {
		var scope = this,
			segments = [base];

		if (criteria) {
			var filters = [],
				page;

			[
				'price',
				'size',
				'space'
			].forEach(function(key) {
				scope.range(criteria, key);
			});

			criteria = $.unserialize(
				$.serialize(criteria)
			);

			Object.keys(scope.parse(base.split('/'), true).structured)
				.forEach(function(key) {
					delete criteria[key];
				});

			if (base === null) { // Intentional
				segments = [
					scope.root()
				];
			}

			if (criteria.page) {
				page = criteria.page;

				delete criteria.page;
			}

			Object.keys(criteria).forEach(function(key) {
				var value = criteria[key];

				if (! value) {
					return;
				}

				if (key === 'location') {
					segments.push(value);
				} else {
					if (Array.isArray(value)) {
						value = value.join('+');
					} else if (key.indexOf('[') > -1) {
						value = LS.util.parseNumber(value);
					} else {
						value = typeof value === 'string' ?
							value.replace(/[ ,]+/g, '+') : value;
					}

					filters.push(key + '=' + encodeURIComponent(value));
				}
			});

			if (base === false) {
				segments = [];
			}

			if (filters.length) {
				segments.push('filter');
				segments.push(
					filters.sort()
						.join(',')
						.replace(/\[]/g, '')
				);
			}

			if (paged !== false && page && page > 1) {
				segments.push('p' + page);
			}
		}

		var path = segments.join('/');

		if (path[0] !== '/') {
			path = '/' + path;
		}

		return path;
	},

	/**
	 * Parse filter string into query parameters
	 *
	 * @param {String} value
	 * @returns {Object}
	 */
	unserialize: function(value) {
		var data = $.unserialize(
			value.replace(/,/g, '&')
		);

		Object.keys(data).forEach(function(key) {
			if (key !== 'keywords') {
				var val = data[key];

				if (val.indexOf(' ') > -1) {
					data[key] = val.split(' ');
				}
			}
		});

		return data;
	},

	/**
	 * Get base page title
	 *
	 * @returns {String}
	 */
	title: function() {
		return $._doc.title.replace(
			/( - [\d,]+ Properties)?( - Page \d+)? - LandSearch/i, ''
		);
	},

	/**
	 * Sort filter range
	 *
	 * @param {Object} filter
	 * @param {String} key
	 */
	range: function(filter, key) {
		var obj = filter[key];

		if (! obj || ! obj.min || ! obj.max) {
			return;
		}

		var values = [
			LS.util.parseNumber(obj.min),
			LS.util.parseNumber(obj.max)
		];

		filter[key].min = Math.min.apply(null, values);
		filter[key].max = Math.max.apply(null, values);
	},

	/**
	 * Get base path
	 *
	 * @returns {String}
	 */
	base: function() {
		return $._loc.pathname
			.replace(/\/p\d+$/, '');
	}
};